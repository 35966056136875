<template>
  <div>
    <v-card>
      <v-toolbar
        color="#0D47A1"
        dark
        height="44px"
      >
        <v-toolbar-title>
          项目推进情况
        </v-toolbar-title>

        <v-spacer />
        <v-btn
          :loading="exporting2"
          small
          color="blue mt-0"
          @click="exportTable"
        >
          导出
        </v-btn>
        <v-btn
          v-if="parentList.data.projectStatus2"
          small
          color="blue mt-0 ml-4"
          @click="openPlan"
        >
          新增
        </v-btn>
      </v-toolbar>
      <!-- 除外商区县 -->
      <v-container
        v-if="!((orgQX && !isMarket) && formId === wsFormId)"
        class="mt-2"
      >
        <v-simple-table fixed-header>
          <thead>
            <tr :class="isXs ? 'titles' : ''">
              <th
                style="min-width:100px"
              >
                填写单位
              </th>
              <th
                style="min-width:90px"
              >
                填写时间
              </th>
              <th
                style="min-width:84px"
              >
                项目进度
              </th>
              <th
                style="min-width:100px"
              >
                对接联系人
              </th>
              <th
                style="min-width:84px"
              >
                联系电话
              </th>
              <th
                style="min-width:200px"
              >
                项目推进具体情况
              </th>
              <th style="min-width:150px">
                推动过程中遇到的困难和问题
              </th>
              <th
                v-permission="['ROLE_ADMIN','ROLE_SITEADMIN','ROLE_TZCJCFZRSHYJ']"

                style="min-width:120px"
              >
                操作
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,inx) in desserts"
            :key="inx"
            :class="isXs ? 'names' : ''"
          >
            <tr>
              <td>{{ item.nickname }}</td>
              <td>{{ item.createdAt | dateformat1 }}</td>
              <td>{{ item.data.progress }}</td>
              <td>{{ item.data.contacts }}</td>
              <td>
                {{ item.data.mobile }}
              </td>
              <td>{{ item.data.situation }}</td>
              <td>{{ item.data.problem }}</td>
              <td
                v-permission="['ROLE_ADMIN','ROLE_SITEADMIN','ROLE_TZCJCFZRSHYJ']"
              >
                <v-icon
                  style="cursor:pointer"
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteMobelBtn(item,'desserts')"
                >
                  fa4 fa-trash-o
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <!-- <v-pagination
          v-model="nowPage"
          :length="pageNum"
          total-visible="10"
          color="indigo darken-4"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="pageChange"
        /> -->
      </v-container>
      <!-- 外商市区县可看 -->
      <v-container
        v-if="orgQX && formId === wsFormId"
      >
        <v-simple-table fixed-header>
          <thead>
            <tr :class="isXs ? 'titles' : ''">
              <th
                style="min-width:84px"
              >
                填写区县
              </th>
              <th
                style="min-width:84px"
              >
                项目进度
              </th>
              <th
                style="min-width:100px"
              >
                对接联系人
              </th>
              <th
                style="min-width:84px"
              >
                联系电话
              </th>
              <th
                style="min-width:200px"
              >
                项目推进具体情况
              </th>
              <th
                style="min-width:150px"
              >
                推动过程中遇到的困难和问题
              </th>
              <th
                style="min-width:80px"
              >
                操作
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,inx) in dessertsOrg"
            :key="inx"
          >
            <tr
              :class="isXs ? 'names' : ''"
            >
              <td>{{ item.nickname }}</td>
              <td>{{ item.data.progress }}</td>
              <td>{{ item.data.contacts }}</td>
              <td>
                {{ item.data.mobile }}
              </td>
              <td>{{ item.data.situation }}</td>
              <td>{{ item.data.problem }}</td>
              <td>
                <v-icon
                  style="cursor:pointer"
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteMobelBtn(item,'dessertsOrg')"
                >
                  fa4 fa-trash-o
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <!-- <v-pagination
          v-model="nowPage"
          :length="pageNum"
          total-visible="10"
          color="indigo darken-4"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="pageChange"
        /> -->
      </v-container>
      <v-dialog
        v-model="imageShow"
        max-width="700"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ photoName }}
          </v-card-title>
          <v-card-text>
            <v-img
              :src="bigPhoto"
              aspect-ratio="1"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="imageShow = false"
            >
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <advance-report
      ref="AdvanceReport"
      @getchild="getchild"
    />
  </div>
</template>

<script>
  export default {
    components: {
      files: () => import('../component/Files.vue'),
      AdvanceReport: () => import('../../../views/report/AdvanceReport.vue'),
      Upload: () => import('../component/Upload.vue'),
    },
    props: {
      formId: {
        type: String,
        default: null,
      },
      reportId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        exporting2: false,
        imgIcon: 'md-document',
        parkCode: [],
        desserts: [],
        dessertsOrg: [],
        parentList: {},
        createData: '',
        searchParame: {
          page: 0,
          size: 999,
          formId: 'c5c289be-3cd1-4f71-96bc-aeff488051fc',
        },
        name: '',
        imageShow: false,
        nowPage: 1,
        pageNum: 0,
        orgQX: false,
        isDeputy: false,
        openItem: {},
        underTake: '',
        pushOrder: [],
        isMarket: false,
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        isXs: false,
      }
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async mounted () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      this.isDeputy = localStorage.isDeputy === 'true' || localStorage.isDeputy === true
      this.isMarket = localStorage.isMarket === 'true' || localStorage.isMarket === true
      await this.getDetail()
      await this.getchild()
    },
    methods: {
      async openPlan () {
        this.$refs.AdvanceReport.open(this.parentList)
      },
      inputFile (val) {
        this.pushOrder = val
      },
      async saveBtn () {
      },
      //! 弹出图片大图
      imageMaxModel (bigPhoto, photoName) {
        this.imageShow = true
        this.bigPhoto = bigPhoto
        this.photoName = photoName
      },
      async getDetail () {
        const resp = await this.$axios.get('/reports/' + this.reportId + '/detail')
        console.log('resp', resp);
        this.parentList = resp.data.data
      },
      deleteMobelBtn (item, type) { // 删除按钮弹出弹框
        this.$swal({
          title: '你确定要删除此项目推进情况吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.deleteProject(item, type)
          }
        })
      },
      deleteProject (item, type) { // 删除项目
        this.$axios.delete('/reports/' + item.id)
          .then(async (res) => {
            if (type === 'dessertsOrg') {
              this.dessertsOrg.splice(this.dessertsOrg.indexOf(item), 1)
            } else {
              this.desserts.splice(this.desserts.indexOf(item), 1)
            }
            this.$swal({
              title: '删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((error) => {
            console.log(error)
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            })
          })
      },
      async getchild () {
        this.$emit('pushInit')
        const paramsData = JSON.parse(JSON.stringify(this.searchParame))
        if (this.orgQX || this.isDeputy) {
          paramsData._from = 'org'
        } else {
          paramsData._from = 'public'
        }
        paramsData.parentID = this.reportId
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        console.log('resp', resp, this.searchParame);
        const desserts = resp.data.data.content
        this.desserts = desserts.filter((val) => {
          return !val.data.isOrg
        })
        this.dessertsOrg = desserts.filter((val) => {
          return val.data.isOrg
        })
        this.pageNum = resp.data.data.totalPages
        // this.desserts = desserts
      },
      pageChange (value) { // 换页按钮
        this.nowPage = value
        this.searchParame.page = value - 1
        this.getchild()
      },
      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      // 导出
      exportTable () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          this.exporting2 = true
          this.$axios.get(`/foreignInvestment/pushOnRoundDoc/${this.reportId}`)
            .then(res => {
              const resData = res.config.url
              this.exportFile(resData)
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      // 导出文件
      async exportFile (url) {
        const data = await this.$axios.get(url, {
          responseType: 'blob',
        })
        const link = document.createElement('a')
        // link.style.display = 'none'
        link.href = window.URL ? window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' })) : window.webkitURL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' }))
        link.setAttribute('download', '项目进展.doc')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.exporting2 = false
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep .v-dialog {
  height: 60% !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}
.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
</style>
